import { ReactElement, useCallback, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router-dom'
import { Form, Input, message } from 'antd'
import { observer } from 'mobx-react'

import userStore from '@store/user'
import guestRoutes from '@router/guest-routes'
import AcceptLicenseAgreement from '@components/common/AcceptLicenseAgreement'
import SubmitButton from '@components/guest-forms/components/SubmitButton'
import { emailRegEx } from '@shared/constants'
import { isApiCustomException, isErrorHasMessage } from '@shared/services/api-service'
import handleApiValidation from '@utils/handle-api-validation'
import { verifyReCaptcha } from '@utils/http/auth'
import isApiValidationError from '@utils/is-api-validation-error'

async function execute<T>(function_: () => Promise<T>): Promise<T> {
  try {
    return await function_()
  } catch (error) {
    if (isErrorHasMessage(error)) {
      message.error(error.message)
    }
    return Promise.reject(error)
  }
}

const SignUpForm = observer((): ReactElement => {
  const [form] = Form.useForm()
  const history = useHistory()
  const [captchaValue, setCaptchaValue] = useState<string | null>(null)
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  const isRecaptchaEnabled = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'

  const handleCaptchaChange = async (value: string | null) => {
    if (!value) {
      setCaptchaValue(null)
      return
    }

    const isValid = await verifyReCaptcha(value)

    if (isValid) {
      setCaptchaValue(value)
    } else {
      message.error('CAPTCHA verification failed. Please try again.')
      setCaptchaValue(null)
    }
  }

  const handleSubmit = useCallback(async ({ email }: { email: string }) => {
    if (isRecaptchaEnabled && !captchaValue) {
      message.error('Please complete the CAPTCHA to proceed')
      return
    }

    try {
      const { userExist } = await execute(() => userStore.signUp(email))

      if (userExist) {
        history.push(guestRoutes.LogIn.path)
      }
    } catch (error) {
      if (isApiCustomException(error, 'UsernameExistsException')) {
        await execute(async () => {
          await userStore.restoreSignUpFlowForUncreatedUser(email)
          await userStore.resendSignUpEmail()
        })
      }

      if (isApiValidationError(error)) {
        handleApiValidation(form, error.data)
      }
    }
  }, [form, history, captchaValue, isRecaptchaEnabled])

  return (
    <Form
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Email is required' },
          {
            pattern: emailRegEx, message: 'Please use valid email', validateTrigger: 'onSubmit',
          },
        ]}
      >
        <Input placeholder="Enter your email" size="large" />
      </Form.Item>
      <Form.Item>
        <ReCAPTCHA
          sitekey={siteKey}
          onChange={handleCaptchaChange}
        />
      </Form.Item>
      <AcceptLicenseAgreement />
      <SubmitButton label="Continue" />
    </Form>
  )
})

export default SignUpForm
