import { KyHeadersInit } from 'ky/distribution/types/options'
import ky, { HTTPError } from 'ky-universal'

import userStore from '@store/user'
import * as Sentry from '@sentry/react'
import AbortError from '@utils/abort-error'

type Headers = Record<string, string | undefined>
type PendingRequest = (status: 'refresh.ok' | 'refresh.error') => void

type ErrorCode =
  | 'CrossSubscriptionLimitsViolationException'
  | 'SubscriptionAlreadyExistsException'
  | 'SubscriptionFileSizeLimitException'
  | 'MaxUploadSizeExceededException'
  | 'SubscriptionLimitException'
  | 'SubscriptionLimitException'
  | 'FileNotFoundByIdException'
  | 'InvalidTemporaryPassword'
  | 'MoleculeUpdateException'
  | 'UsernameExistsException'
  | 'AlreadyExistsException'
  | 'UserNotFoundException'
  | 'ExpiredCodeException'
  | 'InvalidSession'
  | 'MarketingConsentNotFoundException'
  | 'ExportDataException'
  | 'ConvertDataException'
  | 'CopyDataException'

interface ApiCustomException<T> {
  errorCode: ErrorCode
  message: string
  data: T
}

interface PollingRequest<T> {
  url: string
  options?: RequestOptions
  validate: (result: T) => boolean
  interval?: number
  maxAttempts?: number
  transform?: (r: Response) => Promise<T>
}

const TOKEN_REFRESH_FAILED_ERROR = 'Token refresh failed'

const hasErrorCode = <T>(x: unknown): x is ApiCustomException<T> => !!(x as ApiCustomException<T>).errorCode

export const isErrorHasMessage = <T>(error: unknown): error is ApiCustomException<T> | Error => error instanceof Error
  || hasErrorCode(error)

export const isApiCustomException = <T>(error: unknown, errorCode: ErrorCode):
  error is ApiCustomException<T> => hasErrorCode<T>(error) && error.errorCode === errorCode

export const isApiResponseError = (x: unknown): x is HTTPError => typeof (x as ApiResponseError)?.response
  === 'object'

export const isAuthError = (error: unknown): boolean => (isApiResponseError(error) && error?.response?.status === 401)
  || (error as Error)?.message === TOKEN_REFRESH_FAILED_ERROR

class ApiService {
  readonly contentTypeSVG = 'image/svg+xml'
  readonly contentTypeJSON = 'application/json'
  readonly contentTypePlainText = 'text/plain'

  private _authToken = ''
  private pendingRequests: PendingRequest[] = []
  private isRefreshingToken = false

  private get _apiPrefix(): string {
    return window.SDFEConfig.REACT_APP_BACKEND_HOST
  }

  private get _defaultTimeout() {
    return 1000 * Number.parseInt(
      window.SDFEConfig.REACT_APP_BACKEND_DEFAULT_TIMEOUT_SECONDS || '30',
      10,
    )
  }

  public async get<T>(url: string, options?: RequestOptions): Promise<T> {
    return this.http<T>(url, { ...options, method: 'get' })
  }

  public async post<T>(url: string, options?: RequestOptions): Promise<T> {
    return this.http<T>(url, { ...options, method: 'post' })
  }

  public async put<T>(url: string, options?: RequestOptions): Promise<T> {
    return this.http<T>(url, { ...options, method: 'put' })
  }

  public async delete<T>(url: string, options?: RequestOptions): Promise<T> {
    return this.http<T>(url, { ...options, method: 'delete' })
  }

  public async patch<T>(url: string, options?: RequestOptions): Promise<T> {
    return this.http<T>(url, { ...options, method: 'PATCH' })
  }

  set authToken(value: string) {
    this._authToken = value
  }

  private async refreshToken() {
    this.isRefreshingToken = true

    try {
      const token = await this.createRequest<AuthToken>('/auth/refresh', {
        method: 'post',
        body: JSON.stringify(userStore.token),
        withoutAuth: true,
      })

      userStore.setAuthToken(token)
      this.authToken = token.authToken

      this.isRefreshingToken = false
      this.pendingRequests.forEach(request => request('refresh.ok'))
    } catch (error) {
      userStore.signOut().finally(() => {
        this.isRefreshingToken = false
        this.pendingRequests.forEach(request => request('refresh.error'))
      })
    } finally {
      this.pendingRequests = []
    }
  }

  private createPendingRequest<T>(url: string, options?: RequestOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      const request: PendingRequest = status => {
        if (status === 'refresh.ok') {
          this.http<T>(url, options).then(resolve).catch(reject)
        } else {
          reject(new Error(TOKEN_REFRESH_FAILED_ERROR))
        }
      }

      this.pendingRequests.push(request)
    })
  }

  private createPendingUploadRequest(props: UploadFileXhrProps): Promise<UploadedFile> {
    return new Promise((resolve, reject) => {
      const request: PendingRequest = status => {
        if (status === 'refresh.ok') {
          this.uploadFile(props).then(resolve).catch(reject)
        } else {
          reject(new Error(TOKEN_REFRESH_FAILED_ERROR))
        }
      }

      this.pendingRequests.push(request)
    })
  }

  createPollingRequest<T>({
    url,
    options = {},
    validate,
    interval = 1000,
    maxAttempts = Infinity,
  }: PollingRequest<T>): Promise<T> {
    let attempts = 0
    let timeoutId: number

    const run = async (resolve: (value: T | PromiseLike<T>) => void, reject: (e: unknown) => unknown) => {
      try {
        attempts += 1

        const result = await this.http<T>(url, options)

        if (validate(result)) {
          resolve(result)
        } else if (attempts >= maxAttempts) {
          reject('Attempts exceeded maxAttepts')
        } else {
          timeoutId = setTimeout(run, interval, resolve, reject)
        }
      } catch (error) {
        reject(error)
      }
    }

    return new Promise((resolve, reject) => {
      if (options.signal?.aborted) {
        reject(new AbortError())
      }

      options.signal?.addEventListener('abort', () => {
        clearTimeout(timeoutId)
        reject(new AbortError())
      })

      run(resolve, reject)
    })
  }

  uploadFile = async (props: UploadFileXhrProps): Promise<UploadedFile> => {
    try {
      return await this.uploadFileXhrPromise(props)
    } catch (error) {
      if (isAuthError(error)) {
        if (!this.isRefreshingToken) this.refreshToken()
        return this.createPendingUploadRequest(props)
      }

      throw error
    }
  }

  private uploadFileXhrPromise = ({
    path, file, onProgress, abortController,
  }: UploadFileXhrProps): Promise<UploadedFile> => new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', file)

    const request = new XMLHttpRequest()

    request.open('POST', path)
    request.responseType = 'json'

    request.setRequestHeader('Authorization', `Bearer ${this._authToken}`)

    const onAbort = () => {
      request.abort()
      reject(new AbortError())
    }

    const onLoad = () => {
      if (request.status === 200) {
        resolve(request.response)
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          response: {
            ...request.response,
            status: request.status,
          },
        })
      }

      request.upload.removeEventListener('progress', onProgress)
      request.removeEventListener('load', onLoad)
      abortController.signal.removeEventListener('abort', onAbort)
    }

    request.upload.addEventListener('progress', onProgress)
    request.addEventListener('load', onLoad)
    abortController.signal.addEventListener('abort', onAbort)

    request.send(formData)
  })

  async createRequest<T>(url: string, options?: RequestOptions): Promise<T> {
    const headers = this.getHeaders(options?.headers, options?.withoutAuth)
    const fullPath = this.getFullPath(url)

    if (options?.signal?.aborted) throw new AbortError()

    const response = await ky(fullPath, {
      ...options,
      timeout: options?.timeout || this._defaultTimeout,
      headers,
    })

    switch (headers['Content-Type']) {
      case this.contentTypeJSON: return response.json()
      case this.contentTypeSVG: return response.text() as unknown as T
      default: return response.text() as unknown as T
    }
  }

  private async http<T>(url: string, options?: RequestOptions): Promise<T> {
    if (this.isRefreshingToken) return this.createPendingRequest(url, options)

    try {
      return await this.createRequest<T>(url, options)
    } catch (error) {
      if (isApiResponseError(error)) {
        Sentry.captureException(error)

        const { response } = error
        const body = await response.json()

        if (response.status === 401) {
          if (!this.isRefreshingToken) this.refreshToken()

          return this.createPendingRequest(url, options)
        }

        throw body
      }

      throw error
    }
  }

  private getHeaders(headers?: KyHeadersInit, withoutAuth?: boolean): Headers {
    const resultHeaders = { ...headers } as Headers

    if (!resultHeaders['Content-Type']) {
      resultHeaders['Content-Type'] = this.contentTypeJSON
    }

    if (this._authToken && !withoutAuth) {
      resultHeaders.Authorization = `Bearer ${this._authToken}`
    }

    return resultHeaders
  }

  private getFullPath(url: string): string {
    if (!this._apiPrefix?.length) {
      throw new Error('Connection error: API service has no URL prefix!')
    }

    return `${this._apiPrefix}${url}`
  }
}

const apiService = new ApiService()

export default apiService
