import apiService, { isApiResponseError } from '@shared/services/api-service'

const fetchSubscriptionDetails = async (): Promise<SubscriptionDetailsResponse> => {
  try {
    // eslint-disable-next-line max-len
    return await apiService.get<SubscriptionDetailsResponse>('/subscription/paddle/')
  } catch (error) {
    // eslint-disable-next-line max-len, no-mixed-operators
    if (error.message.includes('is not valid.') || (isApiResponseError(error) && error.response?.status === 422)) {
      // eslint-disable-next-line max-len
      throw new Error('We encountered an issue with your subscription data. Please reach out to support (support@adroitdi.com) for help.')
    }
    throw error
  }
}

// TODO: for dev only. remove later
// eslint-disable-next-line
const fetchAll = async (): Promise<any> => apiService.get<any>('/subscription/paddle/all')

export {
  fetchSubscriptionDetails,
  fetchAll,
}
