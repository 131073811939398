import { useEffect } from 'react'
import { notification } from 'antd'
import { reaction } from 'mobx'

import filesStore from '@store/files'

const useWatchFilesRecordLimitError = (): void => {
  useEffect(() => {
    const dispose = reaction(
      () => filesStore.uploadedFiles.filter(file => file.fileParsingErrors
        && Object.keys(file.fileParsingErrors).length
        && Reflect.has(file.fileParsingErrors, -100)),
      filesWithRecordLimitError => {
        if (filesWithRecordLimitError.length === 0) return

        filesWithRecordLimitError.forEach(file => {
          filesStore.removeFile(file.id)

          notification.info({
            message: `Looks like your Adroit DI storage is running low.
            Visit your account settings to easily upgrade your subscription and get the extra space needed.
            Don’t hesitate to reach out if you have any questions - we’re here to ensure you have the tools to manage your data effectively.
            Upgrade your plan today!`,
            duration: 0,
          })
        })
      },
    )

    return () => dispose()
  }, [])
}

export default useWatchFilesRecordLimitError
