import { notification } from 'antd'
import { makeAutoObservable, runInAction } from 'mobx'

import { defaultCurrency } from '@shared/constants'
import {
  fetchSubscriptionDetails,
} from '@utils/http/subscription'
import isPastDate from '@utils/isPastDate'

class SubscriptionStore {
  private _fetchingSubscriptionData = false
  private _status: SDFSubscriptionStatus = localStorage.getItem('subscriptionStatus') as SDFSubscriptionStatus || 'none'
  private _dueDate = ''
  private _subscriptionPlanSettings: SubscriptionPlanSettings | Record<string, never> = {}

  planName: SDFPlanName | 'No plan' = 'No plan'
  planId = -1
  planPrice = 0

  private displayedNotifications = new Set<string>()

  constructor() {
    makeAutoObservable(this)
  }

  async fetchSubscriptionDetails(): Promise<void> {
    if (this._fetchingSubscriptionData) return

    this._fetchingSubscriptionData = true

    try {
      const {
        status,
        nextBillDate,
        cancellationEffectiveDate,
        subscriptionPlanPricing,
        subscriptionPlanSettings,
      } = await fetchSubscriptionDetails()

      runInAction(() => {
        let subscriptionStatus: SDFSubscriptionStatus = 'none'
        let dueDate = ''

        if ((status === 'active' || status === 'trialing') && nextBillDate) {
          dueDate = nextBillDate
          subscriptionStatus = status
        } else if (status === 'deleted' && cancellationEffectiveDate) {
          if (isPastDate(cancellationEffectiveDate)) {
            dueDate = ''
            subscriptionStatus = 'expired'
          } else {
            dueDate = cancellationEffectiveDate
            subscriptionStatus = 'cancelled'
          }
        } else if (status === 'past_due' || status === 'paused') {
          dueDate = ''
          subscriptionStatus = 'paused'
        }

        let foundPrice = 0

        for (let i = 0; i < defaultCurrency.length; i += 1) {
          const currency = defaultCurrency[i]
          const price = subscriptionPlanPricing?.recurringPrices[currency]

          if (price !== undefined) {
            foundPrice = price
            break
          }
        }

        this.planName = subscriptionPlanSettings.planName
        this.planId = Number.parseInt(subscriptionPlanSettings.planId, 10)
        this.planPrice = foundPrice || 0
        this._status = subscriptionStatus
        this._subscriptionPlanSettings = subscriptionPlanSettings
        this._dueDate = new Date(dueDate).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
      })
    } catch (error) {
      runInAction(() => {
        this._status = 'none'
        this.planName = 'No plan'
        this._dueDate = ''

        if (error instanceof Error && error.message.includes('Please reach out to support')) {
          this.showErrorNotification(error.message)
        }
      })
    } finally {
      runInAction(() => {
        this._fetchingSubscriptionData = false
      })

      this.saveStatus()
    }
  }

  private showErrorNotification(message: string): void {
    if (this.displayedNotifications.has(message)) return
    this.displayedNotifications.add(message)
    notification.error({
      message,
      onClose: () => {
        this.displayedNotifications.delete(message)
      },
    })
  }

  async fetchWithRetry(
    fetchFunction: () => Promise<void>,
    maxRetries = 10,
    retryInterval = 2000,
  ): Promise<void> {
    for (let attempt = 1; attempt <= maxRetries; attempt += 1) {
      // eslint-disable-next-line no-await-in-loop
      await fetchFunction()

      if (this._status !== 'none') {
        return
      }

      if (attempt < maxRetries) {
        // eslint-disable-next-line no-await-in-loop
        await this.delay(retryInterval)
      }
    }
    throw new Error(`Exceeded maximum retries (${maxRetries}) and status remains 'none'.`)
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  private saveStatus() {
    localStorage.setItem('subscriptionStatus', this._status)
  }

  clearStatus() {
    this._status = 'none'
    localStorage.removeItem('subscriptionStatus')
    this._fetchingSubscriptionData = false
  }

  get status() {
    return this._status
  }

  get dueDate() {
    return this._dueDate
  }

  get subscriptionPlanSettings() {
    return this._subscriptionPlanSettings
  }

  get fetchingSubscriptionData() {
    return this._fetchingSubscriptionData
  }
}

export default new SubscriptionStore()
