import { ReactElement, useCallback, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input, message } from 'antd'

import userStore from '@store/user'
import routes from '@router/authorized-routes'
import PasswordInput from '@components/common/PasswordInput'
import SubmitButton from '@components/guest-forms/components/SubmitButton'
import { emailRegEx } from '@shared/constants'
import { isErrorHasMessage } from '@shared/services/api-service'
import handleApiValidation from '@utils/handle-api-validation'
import { verifyReCaptcha } from '@utils/http/auth'
import isApiValidationError from '@utils/is-api-validation-error'

const LogInForm = ({ email }: { email?: string }): ReactElement => {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const [captchaValue, setCaptchaValue] = useState<string | null>(null)
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  const isRecaptchaEnabled = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true'

  const handleCaptchaChange = async (value: string | null) => {
    if (!value) {
      setCaptchaValue(null)
      return
    }

    const isValid = await verifyReCaptcha(value)

    if (isValid) {
      setCaptchaValue(value)
    } else {
      message.error('CAPTCHA verification failed. Please try again.')
      setCaptchaValue(null)
    }
  }

  const handleSubmit = useCallback(async (credentials: AuthorizeData) => {
    if (isRecaptchaEnabled && !captchaValue) {
      message.error('Please complete the CAPTCHA to proceed')
      return
    }

    try {
      await userStore.authorize(credentials)

      const isAuthorizedZone = Object.values(routes)
        .map(value => value.path)
        .includes(location.pathname)

      if (!isAuthorizedZone) {
        history.push(routes.Files.path)
      }
    } catch (error) {
      if (isApiValidationError(error)) {
        handleApiValidation(form, error.data)
      }

      if (isErrorHasMessage(error)) {
        message.error(error.message)
      } else {
        throw error
      }
    }
  }, [history, location.pathname, form, captchaValue, isRecaptchaEnabled])

  return (
    <Form
      className="rounded-md"
      form={form}
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        label="Email"
        name="email"
        hidden={!!email}
        rules={[
          { required: true, message: 'Email is required' },
          {
            pattern: emailRegEx, message: 'Please use valid email', validateTrigger: 'onSubmit',
          },
        ]}
        initialValue={email}
      >
        <Input
          placeholder="Enter your email"
          size="large"
          autoComplete="email"
        />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input password' }]}
      >
        <PasswordInput
          placeholder="Enter your password"
          size="large"
          autoComplete="current-password"
        />
      </Form.Item>
      <Form.Item>
        <ReCAPTCHA
          sitekey={siteKey}
          onChange={handleCaptchaChange}
        />
      </Form.Item>
      <SubmitButton label="Sign in" />
    </Form>
  )
}

export default LogInForm
